<template>
  <b-card>
    <div class="fama-table">
      <div v-if="dataLoading" class="spinner-border m-auto" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div v-else-if="error.length !== 0" class="m-auto">
        {{ error }}
      </div>
      <div v-else-if="error.length === 0" style="width: 100%">

        <div v-if="can_list">
          <DxDataGrid
              v-if="can_list"
              id="activity-table"
              ref="data-grid"
              width="100%"
              :show-borders="true"
              :data-source="datas"
              :allow-column-reordering="true"
              :allow-column-resizing="true"
              column-resizing-mode="widget"
              :column-auto-width="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :row-alternation-enabled="true"
              :hover-state-enabled="true"
              key-expr="id"
              exporting="exportGrid"
              @selection-changed="onSelectionChanged"
          >
            <DxSelection
                :select-all-mode="'allPages'"
                :show-check-boxes-mode="'onClick'"
                mode="multiple"
            />
            <DxColumn
                data-field="id"
                :name="this.$t('deleteData.id')"
                :caption="this.$t('deleteData.id')"
            >
            </DxColumn>
            <DxColumn
                data-field="name"
                :name="this.$t('deleteData.name')"
                :caption="this.$t('deleteData.name')"
            >
            </DxColumn>
            <DxFilterRow :visible="true"/>
            <DxSearchPanel :visible="true"/>
            <DxColumnFixing :enabled="true"/>
            <DxColumnChooser :enabled="true"/>
            <DxScrolling mode="standard"/>
            <DxGrouping :context-menu-enabled="true"/>
            <DxGroupPanel :visible="true"/>
            <DxPaging :page-size="20"/>

            <DxPager
                :visible="true"
                :allowed-page-sizes="pageSizes"
                :display-mode="displayMode"
                :show-page-size-selector="showPageSizeSelector"
                :show-info="showInfo"
                :show-navigation-buttons="showNavButtons"
            />
            <DxExport
                :enabled="true"
                :visible="true"
                :allow-export-selected-data="false"
            />
            <DxEditing
                :allow-updating="false"
                :allow-deleting="false"
                :allow-adding="false"
                mode="row"
                :select-text-on-edit-start="true"
                :use-icons="true"
            />
          </DxDataGrid>
          <div class="selected-data">
            <span class="caption">Selected Records:</span>{{ ' ' }}
            <span>
        {{ selectedEmployeeNames }}
      </span>
          </div>
          <button v-if="selectedRowsData.length >0" class="btn btn-danger" @click="deleteFn">
            {{ $t('deleteData.delete') }}
          </button>
        </div>

        <h1 v-else>{{ $t('noPermission') }}</h1>
        <DxPopup
            :visible="deletePop"
            :close-on-outside-click="false"
            :show-close-button="false"
            :title="$t('deleteData.approveToDelete')"
        >
          <div class="dx-field">
            <!--            <div class="dx-field-label">-->
            {{ this.$t('deleteData.areYouSure') }}
            <!--            </div>-->
          </div>
          <div class="dx-field">
            <div class="dx-field-value">
              <b-button
                  class="btn-import mr-1"
                  variant="success"
                  @click="deleteBack()"
              >{{ this.$t('deleteData.imSure') }}
              </b-button
              >
              <b-button
                  class="btn-import mr-1"
                  variant="danger"
                  @click="closeAddingPop()"
              >{{ this.$t('deleteData.cancel') }}
              </b-button>
            </div>
          </div>
        </DxPopup>
      </div>
    </div>
  </b-card>
</template>
<script>
import {BButton, BCard, BCardText, BModal, BSpinner} from 'bootstrap-vue';
import {
  DxColumn, DxColumnChooser,
  DxColumnFixing,
  DxDataGrid,
  DxEditing, DxExport,
  DxFilterRow, DxGrouping, DxGroupPanel,
  DxPager, DxPaging, DxScrolling, DxSearchPanel, DxSelection
} from "devextreme-vue/data-grid";
import {DxPopup} from "devextreme-vue/popup";
import axios from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {readOnly} from "@/auth/utils";

export default {
  components: {
    BButton,
    DxSelection,
    DxEditing,
    DxFilterRow,
    DxColumn,
    DxColumnFixing,
    DxDataGrid,
    DxScrolling,
    DxSearchPanel,
    DxExport,
    BCard,
    DxColumnChooser,
    DxGroupPanel,
    DxGrouping,
    DxPaging,
    DxPopup,
    DxPager
  },
  computed: {
    selectedRowKeys() {
      return this.selectedRowsData.map((employee) => employee.id);
    },
    selectedEmployeeNames() {
      const selectedRowsData = this.selectedRowsData;
      const getEmployeeName = (row) => `${row.name}`;
      return selectedRowsData.length ? selectedRowsData.map(getEmployeeName).join(', ') : 'No item has been selected';
    },
  },
  data() {
    return {
      readOnly: true,
      selectedRowsData: [],
      can_edit: false,
      can_list: true,
      can_create: false,
      can_delete: false,
      displayModes: [
        {text: "Display Mode 'full'", value: 'full'},
        {text: "Display Mode 'compact'", value: 'compact'}
      ],
      displayMode: 'full',
      pageSizes: [20, 50, 100, 200],
      showPageSizeSelector: true,
      showInfo: true,
      showNavButtons: true,
      enterKeyActions: ['startEdit', 'moveFocus'],
      enterKeyDirections: ['none', 'column', 'row'],
      enterKeyDirection: 'row',
      enterKeyAction: 'moveFocus',
      error: '',
      dataLoading: false,
      datas: [
        {id: 1, name: this.$t('navbar.work')},
        {id: 2, name: this.$t('navbar.boq')},
        {id: 3, name: this.$t('navbar.subBoq')},
        {id: 4, name: this.$t('navbar.wbsSubBoq')},
        {id: 5, name: this.$t('navbar.wbs')},
        {id: 6, name: this.$t('navbar.activity')},
        {id: 7, name: this.$t('navbar.material')},
      ],


      deletePop: false,
      rowData: {
        name: '',
        code: '',
        user: 0
      }
    };
  },
  methods: {
    showToast({variant = 'success', title, text, icon = 'XIcon'}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant
        }
      });
    },
    async deleteBack() {
      let res = await axios.post('/delete-data', this.selectedRowsData);
      if (res.status == 200) {
        this.deletePop = false
        this.showToast({
          title: this.$t('deleteData.successTitle'),
          text: this.$t('deleteData.successText'),
          icon: 'InfoIcon'
        })
      }
    },
    closeAddingPop() {
      this.deletePop = false;
    },
    async deleteFn() {
      this.deletePop = true;
    },
    onSelectionChanged({selectedRowsData}) {
      if(!this.readOnly){
          return
      }
      if (!this.selectionChangedBySelectBox) {
        this.prefix = null;
      }

      this.selectedRowsData = selectedRowsData;
      this.selectionChangedBySelectBox = false;
    },
    async getPageAuths() {
      await axios.get('/hierarchy');
      const pageAuths = await axios.post('/get-page-auth', {
        page: 'DeleteData'
      });
      this.can_edit = pageAuths.data.can_edit;
      this.can_list = pageAuths.data.can_list;
      this.can_delete = pageAuths.data.can_delete;
      this.can_create = pageAuths.data.can_create;
    },
  },
  async mounted() {
    this.readOnly = readOnly();
    await this.getPageAuths()
  }
};
</script>
<style>

.bcard {
  background-color: #ffffff;
}

#dropzone-external {
  width: 350px;
  height: 350px;
  background-color: rgba(183, 183, 183, 0.1);
  border-width: 2px;
  border-style: dashed;
  padding: 10px;
}

#dropzone-external > * {
  pointer-events: none;
}

#dropzone-external.dropzone-active {
  border-style: solid;
}

.widget-container > span {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 16px;
}

#dropzone-image {
  max-width: 100%;
  max-height: 100%;
}

#dropzone-text > span {
  font-weight: 100;
  opacity: 0.5;
}

#upload-progress {
  display: flex;
  margin-top: 10px;
}

.flex-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
